<template>
  <v-dialog
    v-model="isOpen"
    max-width="650"
    @click:outside="$emit('update:is-open',false)"
  >
    <v-card class="user-card-detail pa-sm-10 pa-3">
      <v-card-title class="user-edit-title justify-center text-h5">
        {{ t('payment_methods.edit_address') }}
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        {{ t('payment_methods.edit_address_info') }}
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form
          ref="form"
          class="multi-col-validation"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="d-flex align-center"
            >
              <v-text-field
                v-model="paymentMethodComputed.number"
                v-mask="'#### #### #### ####'"
                :label="t('payment_methods.card_number')"
                outlined
                dense
                :append-icon="result.isValid ? icons.mdiCheckUnderline : icons.mdiCreditCardOutline"
                :rules="rules"
                @keyup="identifyCard"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    class="px-0"
                  >
                    <div
                      id="grid"
                      class="grid"
                    >
                      <img
                        id="visa"
                        height="42"
                        :src="require(`@/assets/images/cards/visa.png`)"
                        alt="card"
                        class="card"
                      />
                      <img
                        id="mastercard"
                        height="42"
                        :src="require(`@/assets/images/cards/mastercard.png`)"
                        alt="card"
                        class="card"
                      />
                      <img
                        id="american-express"
                        height="42"
                        :src="require(`@/assets/images/cards/american_express.png`)"
                        alt="card"
                        class="card"
                      />
                      <img
                        id="discover"
                        height="42"
                        :src="require(`@/assets/images/cards/discover.png`)"
                        alt="card"
                        class="card"
                      />
                      <img
                        id="jcb"
                        height="42"
                        :src="require(`@/assets/images/cards/jcb.png`)"
                        alt="card"
                        class="card"
                      />
                      <img
                        id="dinersclub"
                        height="42"
                        :src="require(`@/assets/images/cards/diners_club.png`)"
                        alt="card"
                        class="card"
                      />
                      <img
                        id="maestro"
                        height="42"
                        :src="require(`@/assets/images/cards/maestro.png`)"
                        alt="card"
                        class="card"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col
              cols="12"
              sm="8"
            >
              <v-text-field
                v-model="paymentMethodComputed.owner_name"
                outlined
                dense
                :label="t('payment_methods.name_card')"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-menu
                v-model="modal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="paymentMethodComputed.exp_date"
                    :label="t('payment_methods.expiration_date')"
                    color="primary"
                    outlined
                    dense
                    persistent-hint
                    :prepend-icon="icons.mdiCalendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="paymentMethodComputed.exp_date"
                  no-title
                  color="primary"
                  type="month"
                  :min="dateNow"
                  @input="modal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="paymentMethodComputed.cvv"
                outlined
                dense
                :label="t('payment_methods.cvv')"
                :rules="[required, integerValidator]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model.number="paymentMethodComputed.group_id"
                dense
                outlined
                :label="t('organizations.organization')"
                :items="groups"
                item-text="name"
                item-value="id"
                :readonly="!computedIsAdmin"
                :rules="[required]"
              ></v-select>
            </v-col>
            <v-col
              v-if="paymentMethodComputed.can_default"
              cols="12"
              sm="4"
            >
              <v-switch
                v-model="paymentMethodComputed.default"
                true-value="Y"
                false-value="N"
                hide-details
                class="mt-0"
              >
                <template v-slot:label>
                  <span>{{ t('payment_methods.default_card') }}</span>
                </template>
              </v-switch>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-btn
                color="primary"
                class="me-3"
                @click="saveAction"
              >
                {{ t('tooltip.save') }}
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                @click="() => {
                  cancelAction()
                  $emit('update:is-open', false)
                }"
              >
                {{ t('tooltip.cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, computed, onMounted, watch, onUpdated, onBeforeMount } from '@vue/composition-api'
import { mdiCalendar, mdiCheckUnderline, mdiCreditCardOutline } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { putPaymentMethod, getGroupsActive } from '@api'
import { error, success } from '@core/utils/toasted'
import { required, integerValidator } from '@core/utils/validation'
import useCryptoJs from '@core/utils/useCryptoJs'

import Isotope from 'isotope-layout'
import CardValidator from 'card-validator'

// import { VuePaycard } from 'vue-paycard'

import { VueMaskDirective } from 'v-mask'
import Vue from 'vue'

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const form = ref(null)
    const iso = ref(null)
    const mountedIso = ref(false)
    const isEdit = ref(false)
    const menu = ref(false)
    const modal = ref(false)
    const { userData } = useCryptoJs()
    const computedIsAdmin = computed(() => userData.value.role === 'A')
    const groups = ref([])
    const paymentMethodComputed = computed(() => props.dataParams)
    const result = computed(() => CardValidator.number(paymentMethodComputed.value.number))
    const rules = [value => !!value || 'Required', () => result.value.isValid || 'Invalid Card Number']

    const formatDate = dates => {
      if (!dates) return null
      const [year, month] = dates.split('-')

      return `${month}/${year}`
    }

    const computedExpDate = computed(() => props.dataParams.exp_date)
    const dateNow = new Date().toISOString().substr(0, 10)
    const date = computedExpDate.value
      ? ref(new Date(computedExpDate.value).toISOString().substr(0, 7))
      : ref(new Date().toISOString().substr(0))

    const computedDateFormatted = computed(() => formatDate(date.value))

    const computedIsOpen = computed(() => props.isOpen)

    watch([computedIsOpen], () => {
      if (computedIsOpen.value) {
        setTimeout(async () => {
          date.value = computedExpDate.value
            ? ref(new Date(computedExpDate.value).toISOString().substr(0, 7))
            : ref(new Date().toISOString().substr(0, 7))
        }, 100)
      }
    })

    const identifyCard = () => {
      const niceType = result.value?.card?.niceType
      let filter = '*'
      if (niceType) {
        const type = niceType.toLowerCase().replace(/\s/g, '')
        filter = `#${type}`
      }

      iso.value.arrange({ filter })
    }

    watch([computedIsOpen], () => {
      if (computedIsOpen.value) {
        setTimeout(() => {
          identifyCard()
        }, 100)
      }
    })
    onBeforeMount(() => {
      Vue.directive('mask', VueMaskDirective)
    })

    const initialData = {
      billing_address: null,
      billing_email: null,
      billing_name: null,
      billing_phone: null,
      billing_zip_code: null,
      brand: null,
      cvv: null,
      default: 'N',
      exp_date: null,
      group_id: null,
      number: null,
      owner_name: null,
      type: null,
    }
    const postData = ref(initialData)

    const saveAction = async () => {
      if (form.value.validate()) {
        postData.value = Object.assign(initialData, {
          group_id: paymentMethodComputed.value.group_id,
          owner_name: paymentMethodComputed.value.owner_name,
          number: paymentMethodComputed.value.number,
          brand: result.value.isValid ? result.value.card.niceType : null,
          exp_date: paymentMethodComputed.value.exp_date,
          cvv: paymentMethodComputed.value.cvv,
          default: paymentMethodComputed.value.default ? paymentMethodComputed.value.default : false,
          type: 'c', // paymentMethodComputed.value.type,
          billing_name: paymentMethodComputed.value.billing_name,
          billing_address: paymentMethodComputed.value.billing_address,
          billing_email: paymentMethodComputed.value.billing_email,
          billing_phone: paymentMethodComputed.value.billing_phone,
          billing_zip_code: paymentMethodComputed.value.billing_zip_code,
        })

        const resp = await putPaymentMethod(postData.value, paymentMethodComputed.value.id)
        let message = ''
        if (resp.ok) {
          message = resp.message
          emit('openCloseNew', false)
          success(message)
        } else {
          message = resp.message.text
          error(message)
        }
      }
    }

    const cancelAction = () => {
      form.value.reset()
    }

    onMounted(async () => {
      const response = await getGroupsActive()
      if (userData.value && userData.value.role !== 'A') groups.value = response.data.filter(e => e.id === userData.value.group_id)
      else groups.value = response.data
    })

    onUpdated(() => {
      const grid = document.getElementById('grid')
      if (grid !== null && !mountedIso.value) {
        iso.value = new Isotope(grid, {
          itemSelector: '.card',
          masonry: {
            columnWidth: 25,
          },
        })
        setTimeout(() => {
          iso.value.arrange({ filter: '*' })
        }, 100)
        mountedIso.value = true
      }

      if (mountedIso.value && computedIsOpen.value) {
        setTimeout(() => {
          identifyCard()
        }, 100)
      }
    })

    return {
      isEdit,
      form,
      date,
      dateNow,
      menu,
      modal,
      groups,
      postData,
      userData,

      // Computed
      paymentMethodComputed,
      computedDateFormatted,
      computedIsOpen,
      computedExpDate,
      result,
      computedIsAdmin,

      // i18n
      t,

      // validations
      required,
      integerValidator,
      rules,

      // Methods
      saveAction,
      cancelAction,
      identifyCard,

      // icons
      icons: {
        mdiCalendar,
        mdiCheckUnderline,
        mdiCreditCardOutline,
      },
    }
  },
}
</script>
