<template>
  <div id="video-view">
    <v-row>
      <v-col
        cols="12"
      >
        <v-sheet color="transparent">
          <v-slide-group
            v-model="tab"
            mandatory
            :show-arrows="$vuetify.breakpoint.smAndDown"
            :mobile-breakpoint="$vuetify.breakpoint.thresholds.md + 200"
          >
            <v-slide-item
              v-for="tab in computedTabs"
              :key="tab.icon"
              v-slot="{ active, toggle }"
            >
              <v-btn
                class="mx-2 px-3"
                :input-value="active"
                active-class="secondary white--text"
                depressed
                text
                @click="toggle"
              >
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ tab.icon }}
                </v-icon>
                <span>{{ $t(tab.title) }}</span>
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>

        <v-tabs-items
          id="video-tabs-content"
          v-model="tab"
          class="mt-5 px-2 overflow-visible"
        >
          <v-tab-item
            v-for="tab in computedTabs"
            :key="tab.component"
          >
            <component
              :is="tab.component"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { ref, computed } from '@vue/composition-api'
import {
  mdiCashMultiple,
  mdiCreditCardOutline,
} from '@mdi/js'
import usePermissions from '@core/utils/usePermissions'

import PaymentList from '@/views/payments/payment-list/PaymentList.vue'
import PaymentMethodList from '@/views/payment-methods/PaymentMethodList.vue'

export default {
  components: {
    PaymentList,
    PaymentMethodList,
  },
  setup() {
    const { hasPermission } = usePermissions()

    const userData = ref({})
    const tab = ref(0)
    const tabs = ref([
      {
        icon: mdiCashMultiple, title: 'Billing History', show: hasPermission(81), component: 'payment-list',
      },
      {
        icon: mdiCreditCardOutline, title: 'Payment Methods', show: hasPermission(81), component: 'payment-method-list',
      },
    ])
    const computedTabs = computed(() => tabs.value.filter(e => e.show))

    return {
      userData,
      tab,
      tabs,
      computedTabs,

      hasPermission,
    }
  },
}
</script>
<style lang="scss">
.v-btn--active::before {
  opacity: 0 !important;
}
</style>
