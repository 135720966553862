<template>
  <v-dialog
    v-model="isOpen"
    max-width="800"
    @click:outside="$emit('update:is-open',false)"
  >
    <v-card class="user-card-detail pa-sm-10 pa-3">
      <v-card-title class="user-edit-title justify-center text-h5">
        {{ t('payment_methods.new_card') }}
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        {{ t('payment_methods.new_address_info') }}
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form
          ref="form"
          class="multi-col-validation"
        >
          <v-row>
            <v-col
              cols="12"
              md="5"
              class="d-flex align-center"
            >
              <v-text-field
                v-model="paymentMethodData.number"
                v-mask="'#### #### #### ####'"
                :label="t('payment_methods.card_number')"
                outlined
                dense
                :append-icon="result.isValid ? icons.mdiCheckUnderline : icons.mdiCreditCardOutline"
                :rules="rules"
                @keyup="identifyCard"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="7"
            >
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    class="px-0"
                  >
                    <div
                      id="grid"
                      class="grid"
                    >
                      <img
                        id="visa"
                        height="42"
                        :src="require(`@/assets/images/cards/visa.png`)"
                        alt="card"
                        class="card"
                      />
                      <img
                        id="mastercard"
                        height="42"
                        :src="require(`@/assets/images/cards/mastercard.png`)"
                        alt="card"
                        class="card"
                      />
                      <img
                        id="american-express"
                        height="42"
                        :src="require(`@/assets/images/cards/american_express.png`)"
                        alt="card"
                        class="card"
                      />
                      <img
                        id="discover"
                        height="42"
                        :src="require(`@/assets/images/cards/discover.png`)"
                        alt="card"
                        class="card"
                      />
                      <img
                        id="jcb"
                        height="42"
                        :src="require(`@/assets/images/cards/jcb.png`)"
                        alt="card"
                        class="card"
                      />
                      <img
                        id="dinersclub"
                        height="42"
                        :src="require(`@/assets/images/cards/diners_club.png`)"
                        alt="card"
                        class="card"
                      />
                      <img
                        id="maestro"
                        height="42"
                        :src="require(`@/assets/images/cards/maestro.png`)"
                        alt="card"
                        class="card"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col
              cols="12"
              sm="8"
            >
              <v-text-field
                v-model="paymentMethodData.owner_name"
                outlined
                dense
                :label="t('payment_methods.name_card')"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-menu
                v-model="modal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="paymentMethodData.exp_date"
                    :label="t('payment_methods.expiration_date')"
                    color="primary"
                    outlined
                    dense
                    persistent-hint
                    :prepend-icon="icons.mdiCalendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="paymentMethodData.exp_date"
                  no-title
                  color="primary"
                  type="month"
                  :min="date"
                  @input="modal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="paymentMethodData.cvv"
                outlined
                dense
                :label="t('payment_methods.cvv')"
                :rules="[required, integerValidator]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-switch
                v-model="paymentMethodData.default"
                true-value="Y"
                false-value="N"
                hide-details
                class="mt-0"
              >
                <template v-slot:label>
                  <span>{{ t('payment_methods.default_card') }}</span>
                </template>
              </v-switch>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model.number="paymentMethodData.group_id"
                dense
                outlined
                :label="t('organizations.organization')"
                :items="groups"
                item-text="name"
                item-value="id"
                :readonly="!computedIsAdmin"
                :rules="[required]"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="paymentMethodData.billing_address"
                :label="t('payment_methods.billing_address')"
                outlined
                rows="2"
                :rules="[required]"
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="paymentMethodData.billing_name"
                :label="t('payment_methods.company_name')"
                outlined
                dense
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="paymentMethodData.billing_email"
                :label="t('users.email')"
                outlined
                type="email"
                dense
                :rules="[required, emailValidator]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="paymentMethodData.billing_phone"
                :label="t('payment_methods.contact')"
                outlined
                dense
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="paymentMethodData.status"
                :label="t('payment_methods.state')"
                outlined
                dense
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="paymentMethodData.billing_zip_code"
                :label="t('payment_methods.zip_code')"
                outlined
                dense
                :rules="[required, integerValidator]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-btn
                color="primary"
                class="me-3"
                @click="saveAction"
              >
                {{ t('tooltip.save') }}
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                @click="() => {
                  cancelAction()
                  $emit('update:is-open', false)
                }"
              >
                {{ t('tooltip.cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, onMounted, computed, onBeforeMount, watch, onUpdated } from '@vue/composition-api'
import { mdiCalendar, mdiCheckUnderline, mdiCreditCardOutline } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { postPaymentMethod, getGroupsActive } from '@api'
import { error, success } from '@core/utils/toasted'
import { formatDateSlash } from '@core/utils'
import useCryptoJs from '@core/utils/useCryptoJs'
import { required, emailValidator, integerValidator } from '@core/utils/validation'
import Isotope from 'isotope-layout'
import CardValidator from 'card-validator'

// import { VuePaycard } from 'vue-paycard'

import { VueMaskDirective } from 'v-mask'
import Vue from 'vue'

export default {
  // components: {
  //   //   VuePaycard,
  // },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const iso = ref(null)
    const mountedIso = ref(false)
    const form = ref(null)
    const date = new Date().toISOString().substr(0, 7)
    const menu = ref(false)
    const modal = ref(false)
    const { userData } = useCryptoJs()

    const groups = ref([])
    const computedDateFormatted = computed(() => formatDateSlash(date.value))

    const computedIsOpen = computed({
      set: val => emit('update:is-open', val),
      get: () => props.isOpen,
    })

    const initialData = {
      billing_address: null,
      billing_email: null,
      billing_name: null,
      billing_phone: null,
      billing_zip_code: null,
      brand: null,
      cvv: null,
      default: 'N',
      exp_date: null,
      group_id: userData.value.role !== 'A' ? userData.value.group_id : null,
      number: null,
      owner_name: null,
      type: null,
    }
    const paymentMethodData = ref(initialData)
    const result = computed(() => CardValidator.number(paymentMethodData.value.number))
    const rules = [value => !!value || 'Required', () => result.value.isValid || 'Invalid Card Number']
    const computedIsAdmin = computed(() => userData.value.role === 'A')

    const postData = ref({
      group_id: null,
      owner_name: null,
      number: null,
      brand: null,
      exp_date: null,
      cvv: null,
      default: 'N',
      type: null,
      billing_name: null,
      billing_address: null,
      billing_email: null,
      billing_phone: null,
      billing_zip_code: null,
    })

    watch([computedIsOpen], () => {
      if (computedIsOpen.value) {
        if (userData.value && userData.value.role !== 'A') {
          // facilities.value = response.data.filter(e => e.id === userData.value.facility_id)
          paymentMethodData.value.group_id = userData.value.group_id
        }
        setTimeout(() => {
          iso.value.arrange({ filter: '*' })
        }, 100)
      }
    })
    onBeforeMount(() => {
      Vue.directive('mask', VueMaskDirective)
    })

    const identifyCard = () => {
      const niceType = result.value?.card?.niceType
      let filter = '*'
      if (niceType) {
        const type = niceType.toLowerCase().replace(/\s/g, '')
        filter = `#${type}`
      }

      iso.value.arrange({ filter })
    }

    const cancelAction = () => {
      form.value.reset()
      paymentMethodData.value = Object.assign(initialData, {
        group_id: null,
        owner_name: null,
        number: null,
        brand: null,
        exp_date: null,
        cvv: null,
        default: 'N',
        type: null,
        billing_name: null,
        billing_address: null,
        billing_email: null,
        billing_phone: null,
        billing_zip_code: null,
      })
    }

    const saveAction = async () => {
      if (form.value.validate()) {
        postData.value = Object.assign(initialData, {
          group_id: paymentMethodData.value.group_id,
          owner_name: paymentMethodData.value.owner_name,
          number: paymentMethodData.value.number,
          brand: result.value.isValid ? result.value.card.niceType : null,
          exp_date: paymentMethodData.value.exp_date,
          cvv: paymentMethodData.value.cvv,
          default: paymentMethodData.value.default,
          type: 'c', // paymentMethodData.value.type,
          billing_name: paymentMethodData.value.billing_name,
          billing_address: paymentMethodData.value.billing_address,
          billing_email: paymentMethodData.value.billing_email,
          billing_phone: paymentMethodData.value.billing_phone,
          billing_zip_code: paymentMethodData.value.billing_zip_code,
        })

        const resp = await postPaymentMethod(postData.value)
        let message = ''
        if (resp.ok) {
          message = resp.message
          emit('openCloseNew', false)
          cancelAction()
          success(message)
        } else {
          message = resp.message.text
          error(message)
        }
      }
    }

    onMounted(async () => {
      const response = await getGroupsActive()
      if (userData.value && userData.value.role !== 'A') {
        groups.value = response.data.filter(e => e.id === userData.value.group_id)
      } else groups.value = response.data
    })

    onUpdated(() => {
      const grid = document.getElementById('grid')
      if (grid !== null && !mountedIso.value) {
        iso.value = new Isotope(grid, {
          itemSelector: '.card',
          masonry: {
            columnWidth: 25,
          },
        })
        setTimeout(() => {
          iso.value.arrange({ filter: '*' })
        }, 100)
        mountedIso.value = true
      }
    })

    return {
      form,
      date,
      menu,
      modal,
      groups,
      paymentMethodData,
      postData,
      userData,

      // computed
      computedDateFormatted,
      computedIsOpen,
      result,
      computedIsAdmin,

      // i18n
      t,

      // validations
      required,
      emailValidator,
      integerValidator,
      rules,

      // Methods
      saveAction,
      cancelAction,
      identifyCard,

      // icons
      icons: {
        mdiCalendar,
        mdiCheckUnderline,
        mdiCreditCardOutline,
      },
    }
  },
}
</script>
