<template>
  <div class="user-tab-billings-plans">
    <v-card class="mb-8">
      <v-card-title>
        {{ t('payment_methods.payment_methods') }}
      </v-card-title>

      <v-row class="px-2 ma-0 text-center">
        <v-spacer />

        <v-col
          v-if="hasPermission(11)"
          cols="12"
          sm="2"
        >
          <v-btn
            color="secondary"
            block
            @click="createPaymentMethod"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ t('tooltip.add') }}
          </v-btn>
        </v-col>
      </v-row>

      <!-- <v-fab-transition v-if="hasPermission(11)">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="secondary"
              dark
              absolute
              top
              right
              fab
              class="mb-4 me-3"
              v-on="on"
              @click="createPaymentMethod()"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ t("payment_methods.add_card") }}</span>
        </v-tooltip>
      </v-fab-transition> -->
    </v-card>

    <div v-if="paymentMethods.length">
      <v-card
        v-for="(paymentCard,index) in paymentMethods"
        :key="paymentCard.id"
        outlined
        :class="index < paymentMethods.length-1 ? 'mb-4':''"
      >
        <v-card-text class="d-flex align-sm-center flex-column flex-sm-row">
          <div class="mb-3 mr-6 mb-sm-0">
            <div class="text--primary font-weight-medium d-flex align-center">
              <v-avatar
                :color="false ? '' : 'secondary'"
                :class="false ? 'mx-2' : 'primary--text mx-2'"
                size="32"
              >
                <v-img
                  v-if="paymentCard.group_logo"
                  :src="paymentCard.group_logo"
                ></v-img>
                <span
                  v-else
                  class="font-weight-medium"
                >{{ avatarText(paymentCard.group_name) }}</span>
              </v-avatar>
              <span class="text-base">{{ paymentCard.group_name }}</span>
            </div>
          </div>

          <div class="mb-3 mr-6 mb-sm-0 text-start">
            <div class="text--primary font-weight-medium mt-2 d-flex align-center">
              <v-img
                contain
                max-width="70"
                height="50"
                :src="require(`@/assets/images/cards/${resolvePaymentImgVariant(paymentCard.brand)}`)"
              ></v-img>
              <span class="text-base me-2">{{ paymentCard.owner_name }}</span>

              <v-chip
                v-if="paymentCard.status"
                :color="resolvePaymentStatusVariant(paymentCard.status)"
                label
                small
                :class="`v-chip-light-bg ${resolvePaymentStatusVariant(paymentCard.status)}--text`"
              >
                {{ paymentCard.status_str }}
              </v-chip>

              <v-chip
                v-if="paymentCard.default_str"
                :color="resolvePaymentStatusVariant(paymentCard.status)"
                label
                small
                :class="`v-chip-light-bg warning--text ml-2`"
              >
                {{ paymentCard.default_str }}
              </v-chip>
            </div>
            <span>
              <span class="text-xs pl-2">**** **** **** </span>
              <span>{{ paymentCard.number.substring(paymentCard.number.length - 4) }}</span>
            </span>
          </div>

          <div class="mb-3 mb-sm-0 mr-6 ml-2">
            <p class="mb-0">
              <span class="me-1">{{ t('payment_methods.card_exp') }}</span>
              <span>{{ paymentCard.exp_date }}</span>
            </p>
          </div>

          <v-spacer></v-spacer>

          <div class="text-sm-right text-center">
            <v-row>
              <v-col
                cols="12"
                class="my-3 pa-1 text-center"
              >
                <v-fab-transition v-if="paymentCard.can_edit && hasPermission(12)">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="success"
                        dark
                        icon
                        class="mt-xs-2 ma-0"
                        v-on="on"
                        @click="editPaymentMethod(paymentCard.id, true)"
                      >
                        <v-icon>
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('payment_methods.edit_Card') }}</span>
                  </v-tooltip>
                </v-fab-transition>

                <v-fab-transition v-if="paymentCard.can_delete && hasPermission(13)">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="error"
                        dark
                        icon
                        class="mt-xs-2 ma-0"
                        v-on="on"
                        @click="onDelete(paymentCard.id)"
                      >
                        <v-icon>
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('tooltip.delete') }}</span>
                  </v-tooltip>
                </v-fab-transition>

                <v-fab-transition v-if="paymentCard.status === 'A' && hasPermission(14)">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="#E57373"
                        dark
                        icon
                        class="mt-xs-2 ma-0"
                        v-on="on"
                        @click="onInactivate(paymentCard.id)"
                      >
                        <v-icon>
                          {{ icons.mdiCreditCardRemoveOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('tooltip.disable') }}</span>
                  </v-tooltip>
                </v-fab-transition>

                <v-fab-transition v-if="paymentCard.status === 'I' && hasPermission(14)">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="success"
                        dark
                        icon
                        class="mt-xs-2 ma-0"
                        v-on="on"
                        @click="onActivate(paymentCard.id)"
                      >
                        <v-icon>
                          {{ icons.mdiCreditCardCheckOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('tooltip.enable') }}</span>
                  </v-tooltip>
                </v-fab-transition>

                <v-fab-transition v-if="paymentCard.can_default">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="warning"
                        dark
                        icon
                        class="mt-xs-2 ma-0"
                        v-on="on"
                        @click="onDefault(paymentCard.id)"
                      >
                        <v-icon>
                          {{ icons.mdiStar }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('tooltip.default') }}</span>
                  </v-tooltip>
                </v-fab-transition>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-text>
          <v-card-text class="d-flex align-sm-center flex-column flex-sm-row pa-0">
            <div class="text-sm-left">
              {{ t('payment_methods.billing_address') }}
            </div>

            <v-spacer></v-spacer>

            <div class="text-sm-right">
              <v-fab-transition v-if="paymentCard.can_edit">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="success"
                      dark
                      icon
                      class="mt-xs-2 ma-0"
                      v-on="on"
                      @click="() => {
                        isEditAddressDialogVisible =!isEditAddressDialogVisible
                        editPaymentMethod(paymentCard.id, false)
                      }"
                    >
                      <v-icon>
                        {{ icons.mdiPencilOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ t('payment_methods.edit_billing_address') }}</span>
                </v-tooltip>
              </v-fab-transition>
            </div>
          </v-card-text>

          <v-row class="pt-2">
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <table
                class="billing-address-table"
                style="overflow-wrap: anywhere;"
              >
                <tr>
                  <td class="billing-title">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ t('payment_methods.billing_address') }}:
                    </p>
                  </td>
                  <td>
                    <p>{{ paymentCard.billing_address }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="billing-title">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ t('payment_methods.company_name') }}:
                    </p>
                  </td>
                  <td><p>{{ paymentCard.billing_name }}</p></td>
                </tr>
                <tr>
                  <td class="billing-title">
                    <p class="text-no-wrap text--primary font-weight-medium mb-0">
                      {{ t('payment_methods.billing_email') }}:
                    </p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{ paymentCard.billing_email }}
                    </p>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <table class="billing-address-table">
                <tr>
                  <td class="billing-title">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ t('payment_methods.contact') }}:
                    </p>
                  </td>
                  <td><p>{{ paymentCard.billing_phone }}</p></td>
                </tr>
                <tr>
                  <td class="billing-title">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ t('payment_methods.zip_code') }}:
                    </p>
                  </td>
                  <td><p>{{ paymentCard.billing_zip_code }}</p></td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <div v-else>
      <v-card
        outlined
        class="mb-4"
      >
        <v-card-text class="d-flex justify-center text-body-1">
          {{ t('table.no_data') }}
        </v-card-text>
      </v-card>
    </div>

    <v-dialog
      v-model="isEditAddressDialogVisible"
      max-width="650"
    >
      <v-card class="pa-sm-10 pa-3">
        <v-card-title class="justify-center text-h5 px-5">
          {{ t('payment_methods.edit_address') }}
        </v-card-title>
        <v-card-text class="text-center mt-n2 px-5">
          {{ t('payment_methods.edit_address_info') }}
        </v-card-text>
        <v-card-text class="pt-5">
          <v-form
            ref="form"
            class="multi-col-validation"
          >
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="cardDetail.billing_address"
                  :label="t('payment_methods.billing_address')"
                  outlined
                  rows="2"
                  :rules="[required]"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="cardDetail.billing_name"
                  :label="t('payment_methods.company_name')"
                  outlined
                  dense
                  :rules="[required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="cardDetail.billing_email"
                  :label="t('users.email')"
                  outlined
                  type="email"
                  dense
                  :rules="[required, emailValidator]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="cardDetail.billing_phone"
                  :label="t('payment_methods.contact')"
                  outlined
                  dense
                  :rules="[required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="cardDetail.billing_zip_code"
                  :label="t('payment_methods.zip_code')"
                  outlined
                  dense
                  :rules="[required, integerValidator]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-center"
              >
                <v-btn
                  color="primary"
                  class="me-3"
                  @click="saveAction"
                >
                  {{ t('tooltip.submit') }}
                </v-btn>
                <v-btn
                  outlined
                  color="secondary"
                  @click="() => {
                    isEditAddressDialogVisible= false
                    cancelAction
                  }"
                >
                  {{ t('tooltip.cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <payment-method-edit
      :is-open.sync="isPaymentMethodEditOpen"
      :data-params="cardDetail"
      @openCloseNew="openCloseNew"
    ></payment-method-edit>

    <payment-method-new
      :is-open.sync="isPaymentMethodNewOpen"
      @openCloseNew="openCloseNew"
    ></payment-method-new>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPencilOutline,
  mdiCreditCardCheckOutline,
  mdiCreditCardRemoveOutline,
  mdiStar,
} from '@mdi/js'
import { ref, watch, onMounted } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { required, emailValidator, integerValidator } from '@core/utils/validation'
import { formatDateSlash } from '@core/utils'
import { error, success } from '@core/utils/toasted'
import { avatarText } from '@core/utils/filter'
import usePermissions from '@core/utils/usePermissions'
import useCryptoJs from '@core/utils/useCryptoJs'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import Swal from 'sweetalert2'
import {
  getPaymentMethodsByGroup,
  putPaymentMethod,
  deletePaymentMethods,
  putActivatePaymentMethod,
  putInactivatePaymentMethod,
  putDefaultPaymentMethod,
} from '@api'
import countries from '@/@fake-db/data/other/countries'
import PaymentMethodEdit from './PaymentMethodEdit.vue'
import PaymentMethodNew from './PaymentMethodNew.vue'

export default {
  components: {
    PaymentMethodNew,
    PaymentMethodEdit,
  },
  setup() {
    const { t } = useUtils()
    const { hasPermission } = usePermissions()
    const { userData } = useCryptoJs()
    const { configOrganization } = useSelectOptions()

    const form = ref(null)
    const paymentMethods = ref([])
    const isEditAddressDialogVisible = ref(false)
    const cardDetail = ref({})
    const isPaymentMethodNewOpen = ref(false)
    const isPaymentMethodEditOpen = ref(false)
    const isPaymentMethodBillingEditOpen = ref(false)

    const initialData = {
      group_id: null,
      owner_name: null,
      number: null,
      brand: null,
      exp_date: null,
      cvv: null,
      default: 'N',
      type: null,
      billing_name: null,
      billing_address: null,
      billing_email: null,
      billing_phone: null,
      billing_zip_code: null,
    }

    const postData = ref(initialData)

    const resolvePaymentStatusVariant = status => {
      if (status === 'Primary' || status === 'Default' || status === 'A') return 'success'
      if (status === 'Expired' || status === 'I') return 'error'

      return 'secondary'
    }

    const resolvePaymentStatusTextVariant = status => {
      if (status === 'A' || status === 'a') return 'Activo'
      if (status === 'I' || status === 'i') return 'Inactivo'

      return 'Activo'
    }

    const resolvePaymentImgVariant = img => {
      if (img.toLowerCase().includes('master')) return 'mastercard.png'
      if (img.toLowerCase().includes('visa')) return 'visa.png'
      if (img.toLowerCase().includes('american')) return 'american_express.png'
      if (img.toLowerCase().includes('discover')) return 'discover.png'
      if (img.toLowerCase().includes('jcb')) return 'jcb.png'
      if (img.toLowerCase().includes('maestro')) return 'maestro.png'
      if (img.toLowerCase().includes('diners')) return 'diners.png'

      return 'credit_card.png'
    }

    const loadData = async () => {
      let response = null
      if (userData.value && userData.value.role !== 'A') {
        response = await getPaymentMethodsByGroup(userData.value.group_id)
      } else if (configOrganization.value) {
        response = await getPaymentMethodsByGroup(configOrganization.value)
      }
      if (response) paymentMethods.value = response.data
      else paymentMethods.value = []
    }

    watch([configOrganization], async () => {
      await loadData(configOrganization)
    })

    const openCloseNew = value => {
      loadData()
      isPaymentMethodNewOpen.value = value
      isPaymentMethodEditOpen.value = value
    }

    const createPaymentMethod = () => {
      if (isPaymentMethodNewOpen.value) {
        isPaymentMethodNewOpen.value = false
      } else {
        isPaymentMethodNewOpen.value = true
      }
    }

    const editPaymentMethod = (cardID, isEditCardInfo) => {
      const detail = paymentMethods.value.filter(item => item.id === cardID)
      cardDetail.value = JSON.parse(JSON.stringify(detail[0]))
      Object.assign(cardDetail.value, { saveCardDetails: true })

      if (isEditCardInfo) {
        if (isPaymentMethodEditOpen.value) {
          isPaymentMethodEditOpen.value = false
        } else {
          isPaymentMethodEditOpen.value = true
        }
      }
    }

    const saveAction = async () => {
      if (form.value.validate()) {
        postData.value = Object.assign(initialData, {
          group_id: cardDetail.value.group_id,
          owner_name: cardDetail.value.owner_name,
          number: cardDetail.value.number,
          brand: 'visa', // cardDetail.value.brand,
          exp_date: cardDetail.value.exp_date,
          cvv: cardDetail.value.cvv,
          default: cardDetail.value.default ? cardDetail.value.default : false,
          type: 'c', // cardDetail.value.type,
          billing_name: cardDetail.value.billing_name,
          billing_address: cardDetail.value.billing_address,
          billing_email: cardDetail.value.billing_email,
          billing_phone: cardDetail.value.billing_phone,
          billing_zip_code: cardDetail.value.billing_zip_code,
        })

        const resp = await putPaymentMethod(postData.value, cardDetail.value.id)
        let message = ''
        if (resp.ok) {
          await loadData()
          isEditAddressDialogVisible.value = false
          message = resp.message
          success(message)
        } else {
          message = resp.message.text
          error(message)
        }
      }
    }

    const cancelAction = () => {
      form.value.reset()

      cardDetail.value = Object.assign(initialData, {
        group_id: null,
        owner_name: null,
        number: null,
        brand: null,
        exp_date: null,
        cvv: null,
        default: 'N',
        type: null,
        billing_name: null,
        billing_address: null,
        billing_email: null,
        billing_phone: null,
        billing_zip_code: null,
      })
    }

    const onDelete = async id => {
      Swal.fire({
        title: 'Estas seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await deletePaymentMethods(id)
          if (response.ok) {
            success(response.message)
            loadData()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    const onActivate = async id => {
      Swal.fire({
        title: 'Estas seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Activar!',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await putActivatePaymentMethod(id)
          if (response.ok) {
            success(response.message)
            loadData()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    const onInactivate = async id => {
      Swal.fire({
        title: 'Estas seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Inactivate!',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await putInactivatePaymentMethod(id)
          if (response.ok) {
            success(response.message)
            loadData()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    const onDefault = async id => {
      Swal.fire({
        title: 'Estas seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Actualizar!',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await putDefaultPaymentMethod(id)
          if (response.ok) {
            success(response.message)
            loadData()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    onMounted(async () => {
      await loadData()
    })

    return {
      countries,
      cardDetail,
      form,
      userData,
      postData,
      paymentMethods,
      isPaymentMethodEditOpen,
      isPaymentMethodNewOpen,
      isPaymentMethodBillingEditOpen,
      isEditAddressDialogVisible,

      resolvePaymentStatusVariant,
      resolvePaymentStatusTextVariant,
      resolvePaymentImgVariant,
      createPaymentMethod,
      editPaymentMethod,
      openCloseNew,
      loadData,
      saveAction,
      cancelAction,
      onDelete,
      onActivate,
      onInactivate,
      onDefault,
      formatDateSlash,
      avatarText,
      hasPermission,

      // validations
      required,
      emailValidator,
      integerValidator,

      // i18n
      t,

      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPencilOutline,
        mdiCreditCardCheckOutline,
        mdiCreditCardRemoveOutline,
        mdiStar,
      },
    }
  },
}
</script>
